<template>
    <div class="app-content">
        <!-- <div style="margin-bottom:20px">
    <el-card class="box-card">
      <b>
        {{title}}
      </b>
    </el-card>
  </div> -->
        <el-form
            ref="ruleForm"
            :model="form"
            :rules="rules"
            label-width="80px"
            label-position="top"
        >
            <el-row type="flex" justify="center">
                <el-col :sm="20" :lg="16">
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            <b>企业信息</b>
                        </div>
                        <el-row :gutter="20">
                            <el-col :span="8" v-if="$route.query.type == 0">
                                <el-form-item label="所属企业组" prop="groupId">
                                    <el-select
                                        style="width: 100%"
                                        v-model="form.groupId"
                                        :multiple="multiple"
                                        collapse-tags
                                        clearable
                                        placeholder="请选择"
                                        v-if="isShow"
                                    >
                                        <el-option
                                            v-for="item in groupList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                            :disabled="item.disabled"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <!-- <el-col :span="8" v-if="$route.query.type !== 1">
                                <el-form-item label="企业标签" prop="type">
                                    <el-select
                                        style="width: 100%"
                                        v-model="form.type"
                                        placeholder="请选择"
                                        disabled
                                    >
                                        <el-option
                                            v-for="item in companyTypeList"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col> -->
                            <el-col :span="8">
                                <el-form-item label="信用代码" prop="uscc">
                                    <el-input
                                        v-model="form.uscc"
                                        placeholder="请输入"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8" v-if="$route.query.type !== 1">
                                <el-form-item label="信用代码2" prop="usccAs">
                                    <el-input
                                        v-model="form.usccAs"
                                        placeholder="请输入"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="企业名称" prop="name">
                                    <el-input
                                        v-model="form.name"
                                        placeholder="请输入"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item
                                    label="海关代码"
                                    prop="customsCode"
                                >
                                    <el-input
                                        v-model="form.customsCode"
                                        placeholder="请输入"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item
                                    label="省级名称"
                                    prop="provinceName"
                                >
                                    <el-input
                                        v-model="form.provinceName"
                                        placeholder="请输入"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item
                                    label="省级代码"
                                    prop="provinceCode"
                                >
                                    <el-input
                                        v-model="form.provinceCode"
                                        placeholder="请输入"
                                        @blur="blurChange"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="市级名称" prop="cityName">
                                    <!-- <el-input
                  v-model="form.cityName"
                  placeholder="请输入"
                /> -->
                                    <el-select
                                        v-model="form.cityName"
                                        filterable
                                        placeholder="请选择"
                                        no-data-text="请先输入省级代码"
                                    >
                                        <el-option
                                            v-for="(item, index) in cityList"
                                            :key="index"
                                            :label="item.areaName"
                                            :value="item.areaName"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="市级代码" prop="cityCode">
                                    <el-input
                                        v-model="form.cityCode"
                                        placeholder="请输入"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item
                                    label="县级名称"
                                    prop="countyName"
                                >
                                    <el-select
                                        v-model="form.countyName"
                                        filterable
                                        placeholder="请选择"
                                        no-data-text="请先选择市级名称"
                                    >
                                        <el-option
                                            v-for="(item, index) in countyList"
                                            :key="index"
                                            :label="item.areaName"
                                            :value="item.areaName"
                                        >
                                        </el-option>
                                    </el-select>
                                    <!-- <el-input
                  v-model="form.countyName"
                  placeholder="请输入"
                /> -->
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item
                                    label="县级代码"
                                    prop="countyCode"
                                >
                                    <el-input
                                        v-model="form.countyCode"
                                        placeholder="请输入"
                                    />
                                </el-form-item>
                            </el-col>

                            <el-col :span="8">
                                <el-form-item
                                    label="企业性质"
                                    prop="businessType"
                                >
                                    <el-select
                                        style="width: 100%"
                                        v-model="form.businessType"
                                        placeholder="请选择"
                                    >
                                        <el-option
                                            v-for="item in businessTypeList"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                        >
                                        </el-option>
                                    </el-select>
                                    <!-- <el-input
                  v-model="form.businessType"
                  placeholder="请输入"
                /> -->
                                </el-form-item>
                            </el-col>
                            <!-- <el-col :span="8" v-if="$route.query.type == 0">
              <el-form-item
                label="所属企业组"
                prop="groupId"
              >
               <el-select
                  style="width:100%"
                  v-model="form.groupId"
                  multiple 
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in groupList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
                        </el-row>
                    </el-card>
                    <el-card
                        class="box-card bottom-card"
                        style="margin-top: 10px"
                    >
                        <el-row type="flex" justify="end">
                            <!-- <el-button @click="resetForm('ruleForm')">取 消</el-button> -->
                            <el-button
                                type="primary"
                                @click="submitForm('ruleForm')"
                                >提 交</el-button
                            >
                        </el-row>
                    </el-card>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import {
    getCompanyGroupListApi,
    getCompanyTempDetailApi,
    getCompanyDetailApi,
    getCompanyCreateApi,
    getCompanyUpdateApi,
    getCompanyChainListApi,
    getCompanyChainCreateApi,
    getCompanyChainUpdateApi,
    getCompanyTempCreateApi,
    getCompanyTempUpdateApi,
} from '@/api/company'
import {surveyCompanyStatisticsApi} from '@/api/analysisResultApi'
import { districtListApi } from '@/api/district'
export default {
    data() {
        var validateUscc = (rule, value, callback) => {
            if (value == '') {
                callback(new Error('请输入统一社会信用代码'))
            } else {
                if (!/^[0-9A-Za-z]{18}$/.test(value)) {
                    callback(new Error('统一社会信用代码格式错误'))
                } else {
                    callback()
                }
            }
        }
        return {
            companyTypeList: this.enterpriseLabel,
            businessTypeList: [
                {
                    label: '外贸',
                    value: 'WM',
                },
                {
                    label: '外资',
                    value: 'WZ',
                },
                {
                    label: '外经',
                    value: 'WJ',
                },
            ],
            title: '新增模板',
            form: {},
            rules: {
                uscc: [
                    {
                        validator: validateUscc,
                        trigger: 'blur',
                    },
                    {
                        required: true,
                        message: '请输入信用代码',
                        trigger: 'blur',
                    },
                ],
                name: [
                    {
                        required: true,
                        message: '请输入企业名称',
                        trigger: 'blur',
                    },
                ],
								groupId: [
                    { required: true, message: '请选择所属企业组', trigger: 'blur' }
                ],
                // type: [
                //     { required: true, message: '请选择企业标签', trigger: 'blur' }
                // ],
                businessType: [
                    {
                        required: true,
                        message: '请选择企业性质',
                        trigger: 'blur',
                    },
                ],
            },
            cityList: [], // 地市数组
            countyList: [], // 县级数组
            groupList: [], //所属企业组
            multiple: true,
            formType: [],
            isShow: true,
        }
    },
    watch: {
        'form.cityName'(n, o) {
            console.log('n', n, o)
            var list = this.cityList.filter((item) => item.areaName === n)
            console.log('list', list)
            if (list.length > 0) {
                this.$set(this.form, 'cityCode', list[0].areaCode)
            }
        },
        'form.cityCode'() {
            console.log('shuju')
            this.countyFun()
        },
        'form.countyName'(n) {
            var list = this.countyList.filter((item) => item.areaName === n)
            if (list.length > 0) {
                this.$set(this.form, 'countyCode', list[0].areaCode)
            }
        },
        'form.provinceCode'() {
            this.cityFun()
        },
        // 监听企业组
        'form.groupId'(n) {
					 //企业组标签先默认为空，后面有对应值再赋值
						this.form.type = null
            console.log('n', n)
            // this.formType = []
            var formType = []
            // var ddd=[]
            for (let index = 0; index < n.length; index++) {
                const element = n[index]
                var ddd = this.groupList.filter((item) => {
                    return item.id === element
                })
                formType.push(ddd[0].groupLabel)
            }

            if (formType.filter((item) => item === 'YB').length > 0) {
                this.form.type = 'YB'
                return true
            }
            if (
                formType.filter((item) => item === 'YB').length == 0 &&
                formType.filter((item) => item === 'ZB').length > 0
            ) {
                this.form.type = 'ZB'
                return true
            }
            if (
                formType.filter((item) => item === 'YB').length == 0 &&
                formType.filter((item) => item === 'ZB').length == 0 &&
                formType.filter((item) => item === 'FYB').length > 0
            ) {
                this.form.type = 'FYB'
            }
        },
    },
    created() {
        this.getCompanyGroupListFun()
        // type：企业：0， 小程序：1， 产业链企业2
        if (this.$route.query.id && this.$route.query.type === 1) {
            // 请求小程序企业详情
            this.getCompanyTempDetailFun()
        } else if (this.$route.query.id && this.$route.query.type !== 1) {
            this.getCompanyDetailFun()
        }
    },
    methods: {
        // 获取所有有效企业组
        async getCompanyGroupListFun() {
            // var start = currentPage ? currentPage * pageSize - pageSize : 0
            var params = {
                start: 0,
                length: 10000,
                draw: 1,
                orderStr: 'time desc',
                status:0
            }
            var res = await getCompanyGroupListApi(params)
            this.groupList = res.data
            console.log('groupList', this.groupList)
        },
        // 省级代码失去焦点
        blurChange() {
            this.cityFun()
        },
        // 请求地区代码
        async cityFun() {
            var params = {
                start: 0,
                length: 9999,
                draw: 1,
                orderStr: 'id desc',
                areaPcode: this.form.provinceCode,
            }
            var res = await districtListApi(params)
            this.cityList = res.data
        },
        // 请求区县代码
        async countyFun() {
            var params = {
                start: 0,
                length: 9999,
                draw: 1,
                orderStr: 'id desc',
                areaPcode: this.form.cityCode,
            }
            var res = await districtListApi(params)
            this.countyList = res.data
        },
        async getCompanyTempDetailFun() {
            var res = await getCompanyTempDetailApi(this.$route.query.id)
            this.form = res
        },
        async getCompanyDetailFun() {
            var params = {
                uscc: this.$route.query.id,
            }
            var res = await getCompanyDetailApi(params)
            this.form = res.data
        },
        async getCompanyChainListFun(currentPage, pageSize) {
            var start = currentPage ? currentPage * pageSize - pageSize : 0
            var params = {
                start: start,
                length: 10,
                draw: 1,
                uscc: this.$route.query.id,
            }
            var res = await getCompanyChainListApi(params)
            this.form = res.data[0]
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                console.log('valid', valid)
                if (valid) {
                    if (this.$route.query.type == 0) {
                        //   this.form.type = this.formType.toString()
                        this.form.groupId =
                            this.form.groupId
                                ? this.form.groupId
                                : this.form.groupId.split(',')
                        console.log('form', this.form)
                        if (this.$route.query.id) {
                            this.getCompanyUpdateFun()
                        } else {
                            this.getCompanyCreateFun()
                        }
                    } else if (this.$route.query.type == 2) {
                        if (this.$route.query.id) {
                            this.getCompanyChainUpdateFun()
                        } else {
                            this.getCompanyChainCreateFun()
                        }
                    } else if (this.$route.query.type == 1) {
                        if (this.$route.query.id) {
                            this.getCompanyTempUpdateFun()
                        } else {
                            this.getCompanyTempCreateFun()
                        }
                    }
                } else {
                    this.$message({
                        type: 'error',
                        message: '请检查是否有提示选项'
                    })
                    console.log('error submit!!')
                    return false
                }
            })
        },
        // 新增提交-企业
        async getCompanyCreateFun() {
            var res = await getCompanyCreateApi(this.form)
            if (res.code === 200) {
                this.$message({
                    message: '提交成功',
                    type: 'success',
                })
                this.$router.go(-1)
            } else {
                this.$message({
                    message: '提交失败，' + res.message,
                    type: 'error',
                })
            }
        },
        // 更新提交-企业
        async getCompanyUpdateFun() {
            delete this.form.time
            // delete this.form.groupId
            delete this.form.groups
            delete this.form.verifyTime
            console.log('this.form', this.form)
            var res = await getCompanyUpdateApi(this.form)
            if (res.code === 200) {
                this.$message({
                    message: '提交成功',
                    type: 'success',
                })
                this.$router.go(-1)
            } else {
                this.$message({
                    message: '提交失败，' + res.message,
                    type: 'error',
                })
            }
        },

        // 新增提交-产业链
        async getCompanyChainCreateFun() {
            var res = await getCompanyChainCreateApi(this.form)
            if (res.code === 200) {
                this.$message({
                    message: '提交成功',
                    type: 'success',
                })
                this.$router.go(-1)
            } else {
                this.$message({
                    message: '提交失败，' + res.message,
                    type: 'error',
                })
            }
        },
        // 更新提交-产业链
        async getCompanyChainUpdateFun() {
            delete this.form.time
            var res = await getCompanyChainUpdateApi(this.form)
            if (res.code === 200) {
                this.$message({
                    message: '提交成功',
                    type: 'success',
                })
                this.$router.go(-1)
            } else {
                this.$message({
                    message: '提交失败，' + res.message,
                    type: 'error',
                })
            }
        },
        // 新增提交-小程序企业
        async getCompanyTempCreateFun() {
            var res = await getCompanyTempCreateApi(this.form)
            if (res.code === 200) {
                this.$message({
                    message: '提交成功',
                    type: 'success',
                })
                this.$router.go(-1)
            } else {
                this.$message({
                    message: '提交失败，' + res.message,
                    type: 'error',
                })
            }
        },
        // 更新提交-小程序企业
        async getCompanyTempUpdateFun() {
            delete this.form.time
            delete this.form.verifyTime
            delete this.form.groupId
            delete this.form.groups
            var res = await getCompanyTempUpdateApi(this.form)
            if (res.code === 200) {
                this.$message({
                    message: '提交成功',
                    type: 'success',
                })
                this.$router.go(-1)
            } else {
                this.$message({
                    message: '提交失败，' + res.message,
                    type: 'error',
                })
            }
        },
    },
}
</script>

<style scoped>
.app-content {
    width: 1200px;
    margin: 20px auto 20px auto;
    /* margin-top: 20px; */
}
::v-deep .el-form-item {
    width: 100%;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}
</style>
